import axios from "axios";
const apiUrl = 'https://bndv-sites-templates-api.azurewebsites.net/graphql';
const companyId = 160;

export const fetchGetAdsByCompany = ({
    pagination,
    filters,
    orderBy
}) => {
    const query = `
        query {
            ads_by_company(
                pagination: { page: ${pagination.page}, pageSize: ${pagination.pageSize} }
                filters: {
                    idCompany: ${companyId}
                    ${filters.brands ? `brands: ${JSON.stringify(filters.brands.split('|'))}` : ''}
                    ${filters.models ? `models: ${JSON.stringify(filters.models.split('|'))}` : ''}
                    ${filters.years ? `years: ${JSON.stringify(filters.years.split('|'))}` : ''}
                    ${filters.transmissions ? `transmissions: ${JSON.stringify(filters.transmissions.split('|'))}` : ''}
                    ${filters.fuels ? `fuels: ${JSON.stringify(filters.fuels.split('|'))}` : ''}
                    ${filters.price_from ? `price_from: ${filters.price_from}` : ''}
                    ${filters.price_to ? `price_to: ${filters.price_to}` : ''}
                    ${filters.featured ? `featured: ${filters.featured}` : ''}
                    ${filters.condition ? `condition: ${filters.condition}` : ''}
                }
                ${orderBy ? `orderBy: ${orderBy}` : ''}
            ) 
            {
                paginationOut { totalRows }
                items {
                    id
                    brand
                    model
                    version
                    mileage
                    yearFabrication
                    yearModel
                    doors
                    fuel
                    transmission
                    color
                    plate
                    promotionalPrice
                    price
                    description
                    featured
                    armored
                    condition
                    category
                    optionals
                    photos
                }
            }
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.ads_by_company;
    }).catch(err => { throw Error(err.message); });
}

export const fetchGetAdsFilters = ({ condition }) => {
    const query = `
        query {
            ads_filters(
                idCompany: ${companyId}
                ${condition ? `condition: ${condition}` : ''}
            ) {
                brands { name quantity }
                models { name quantity }
                yearsModel { name quantity }
                transmissions { name quantity }
                fuels { name quantity }
            }
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.ads_filters;
    }).catch(err => { throw Error(err.message); });
}

export const fetchGetAd = ({ idAd }) => {
    const query = `
        query {
            ad(
                idCompany: ${companyId} 
                idAd: ${idAd}
            ) 
            {
                id
                brand
                model
                version
                mileage
                yearFabrication
                yearModel
                doors
                fuel
                transmission
                color
                plate
                promotionalPrice
                price
                description
                featured
                armored
                condition
                datetime
                category
                optionals
                photos
                videos
            }
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.ad;
    }).catch(err => { throw Error(err.message); });
}

export const fetchPostEmailSendPurpose = ({
    idAd,
    name,
    email,
    cellPhone,
    message,
    token
}) => {
    const query = `
        mutation {
            email_send_purpose (
                idCompany: ${companyId}
                idAd: ${idAd}
                name: "${name}"
                email: "${email}"
                cellPhone: "${cellPhone}"
                message: ${JSON.stringify(message)}
                token: "${token}"
            ) 
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.email_send_purpose;
    }).catch(err => { throw Error(err.message); });
}

export const fetchGetBanners = ({ idMenuOption }) => {
    const query = `
        query {
            company_banners (
                idCompany: ${companyId}
                idMenuOption: ${idMenuOption}
            ) {
                id
                menuOptions
                banner {
                    id
                    imageUrl
                }
            } 
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.company_banners;
    }).catch(err => { throw Error(err.message); });
}

export const fetchPostEmailSendContact = ({
    name,
    email,
    cellPhone,
    message,
    token
}) => {
    const query = `
        mutation {
            email_send_contact (
                idCompany: ${companyId}
                name: "${name}"
                email: "${email}"
                cellPhone: "${cellPhone}"
                message: ${JSON.stringify(message)}
                token: "${token}"
            ) 
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.email_send_contact;
    }).catch(err => { throw Error(err.message); });
}

export const fetchPostEmailSendSellYourVehicle = ({
    vehicle,
    mileage,
    name,
    email,
    cellPhone,
    value,
    token
}) => {
    const query = `
        mutation {
            email_send_sell_your_vehicle (
                idCompany: ${companyId}
                vehicle: "${vehicle}"
                mileage: "${mileage}"
                name: "${name}"
                email: "${email}"
                cellPhone: "${cellPhone}"
                value: "${value}"
                token: "${token}"
            ) 
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.email_send_sell_your_vehicle;
    }).catch(err => { throw Error(err.message); });
}

export const fetchPostEmailSendFinancing = ({
    vehicle,
    vehicleValue,
    entryValue,
    financingValue,
    paymentTerm,
    name,
    email,
    motherName,
    fatherName,
    birthDate,
    cellPhone,
    cpf,
    rg,
    rgIssueDate,
    rgIssuerOrganization,
    hasCNH,
    addressStreet,
    addressNumber,
    addressComplement,
    addressNeghborhood,
    addressPostalCode,
    addressCity,
    addressFederativeUnit,
    addressResidenceType,
    addressResidenceTime,
    workActivityCompany,
    workActivityTimeOfWork,
    workActivityOffice,
    workActivitySector,
    workActivityAddressStreet,
    workActivityAddressNumber,
    workActivityAddressComplement,
    workActivityAddressNeighborhood,
    workActivityAddressPostalCode,
    workActivityAddressCity,
    workActivityAddressFederativeUnit,
    workActivitySalary,
    workActivityOtherLace,
    bankReferenceBank,
    bankReferenceAgency,
    bankReferenceAccount,
    bankReferenceCustomerSince,
    token
}) => {
    const query = `
        mutation {
            email_send_financing (
                idCompany: ${companyId}
                vehicle: "${vehicle}"
                vehicleValue: "${vehicleValue}"
                entryValue: "${entryValue}"
                financingValue: "${financingValue}"
                paymentTerm: "${paymentTerm}"
                name: "${name}"
                email: "${email}"
                motherName: "${motherName}"
                fatherName: "${fatherName}"
                birthDate: "${birthDate}"
                cellPhone: "${cellPhone}"
                cpf: "${cpf}"
                rg: "${rg}"
                rgIssueDate: "${rgIssueDate}"
                rgIssuerOrganization: "${rgIssuerOrganization}"
                hasCNH: "${hasCNH}"
                addressStreet: "${addressStreet}"
                addressNumber: "${addressNumber}"
                addressComplement: "${addressComplement}"
                addressNeghborhood: "${addressNeghborhood}"
                addressPostalCode: "${addressPostalCode}"
                addressCity: "${addressCity}"
                addressFederativeUnit: "${addressFederativeUnit}"
                addressResidenceType: "${addressResidenceType}"
                addressResidenceTime: "${addressResidenceTime}"
                workActivityCompany: "${workActivityCompany}"
                workActivityTimeOfWork: "${workActivityTimeOfWork}"
                workActivityOffice: "${workActivityOffice}"
                workActivitySector: "${workActivitySector}"
                workActivityAddressStreet: "${workActivityAddressStreet}"
                workActivityAddressNumber: "${workActivityAddressNumber}"
                workActivityAddressComplement: "${workActivityAddressComplement}"
                workActivityAddressNeighborhood: "${workActivityAddressNeighborhood}"
                workActivityAddressPostalCode: "${workActivityAddressPostalCode}"
                workActivityAddressCity: "${workActivityAddressCity}"
                workActivityAddressFederativeUnit: "${workActivityAddressFederativeUnit}"
                workActivitySalary: "${workActivitySalary}"
                workActivityOtherLace: "${workActivityOtherLace}"
                bankReferenceBank: "${bankReferenceBank}"
                bankReferenceAgency: "${bankReferenceAgency}"
                bankReferenceAccount: "${bankReferenceAccount}"
                bankReferenceCustomerSince: "${bankReferenceCustomerSince}"
                token: "${token}"
            ) 
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.email_send_financing;
    }).catch(err => { throw Error(err.message); });
}

export const fetchPostEmailSendInsurance = ({
    ownerName,
    ownerEmail,
    ownerCpf,
    ownerCellPhone,
    mainConductorName,
    mainConductorCpf,
    mainConductorGender,
    mainConductorCivilState,
    mainConductorHasChild,
    mainConductorAddressPostalCode,
    mainConductorAddressLivesWith,
    mainConductorHasResidenceGarage,
    mainConductorHasWork,
    mainConductorPeriodOfWork,
    mainConductorHasWorkGarage,
    mainConductorSchooling,
    mainConductorPeriodOfStudy,
    mainConductorHasSchoolGarage,
    mainConductorPracticeSport,
    mainConductorHasDefenseDrivingCourse,
    mainConductorDrivingAtNight,
    vehicle,
    vehicleIsNew,
    vehicleHasYoungConductor,
    vehicleHasAnotherVehicle,
    vehicleMileagePerMonth,
    vehicleUtilization,
    vehicleConductorAndInsurerRealtion,
    token
}) => {
    const query = `
        mutation {
            email_send_insurance (
                idCompany: ${companyId}
                ownerName: "${ownerName}"
                ownerEmail: "${ownerEmail}"
                ownerCpf: "${ownerCpf}"
                ownerCellPhone: "${ownerCellPhone}"
                mainConductorName: "${mainConductorName}"
                mainConductorCpf: "${mainConductorCpf}"
                mainConductorGender: "${mainConductorGender}"
                mainConductorCivilState: "${mainConductorCivilState}"
                mainConductorHasChild: "${mainConductorHasChild}"
                mainConductorAddressPostalCode: "${mainConductorAddressPostalCode}"
                mainConductorAddressLivesWith: "${mainConductorAddressLivesWith}"
                mainConductorHasResidenceGarage: "${mainConductorHasResidenceGarage}"
                mainConductorHasWork: "${mainConductorHasWork}"
                mainConductorPeriodOfWork: "${mainConductorPeriodOfWork}"
                mainConductorHasWorkGarage: "${mainConductorHasWorkGarage}"
                mainConductorSchooling: "${mainConductorSchooling}"
                mainConductorPeriodOfStudy: "${mainConductorPeriodOfStudy}"
                mainConductorHasSchoolGarage: "${mainConductorHasSchoolGarage}"
                mainConductorPracticeSport: "${mainConductorPracticeSport}"
                mainConductorHasDefenseDrivingCourse: "${mainConductorHasDefenseDrivingCourse}"
                mainConductorDrivingAtNight: "${mainConductorDrivingAtNight}"
                vehicle: "${vehicle}"
                vehicleIsNew: "${vehicleIsNew}"
                vehicleHasYoungConductor: "${vehicleHasYoungConductor}"
                vehicleHasAnotherVehicle: "${vehicleHasAnotherVehicle}"
                vehicleMileagePerMonth: "${vehicleMileagePerMonth}"
                vehicleUtilization: "${vehicleUtilization}"
                vehicleConductorAndInsurerRealtion: "${vehicleConductorAndInsurerRealtion}"
                token: "${token}"
            ) 
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.email_send_insurance;
    }).catch(err => { throw Error(err.message); });
}

export const fetchGetCompanyGeneralData = () => {
    const query = ` query { website_company_general_data (idCompany: ${companyId}) } `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.website_company_general_data;
    }).catch(err => { throw Error(err.message); });
}

export const fetchGetCompanySEO = () => {
    const query = `
        query {
            company_seo (idCompany: ${companyId}) {
                googleAnalyticsId
                facebookPixelId
            } 
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.company_seo;
    }).catch(err => { throw Error(err.message); });
}

export const fetchInsertPageView = ({ page }) => {
    const query = `
        mutation {
            insert_pageview(
                idCompany: ${companyId}
                page: "${page}"
            )
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.insert_pageview;
    }).catch(err => { throw Error(err.message); });
}

export const fetchGetAdsFilterModelByBrand = ({ idCategory, brand }) => {
    const query = `
        query {
            ads_filters_modelbybrand(
                idCompany: ${companyId}
                ${idCategory ? `idCategory: ${idCategory}` : ''}
                brand: "${brand}"
            ) {                
                models { name quantity }                
            }
        }
    `;

    return axios.post(
        apiUrl,
        JSON.stringify({ query: query }),
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
    ).then(res => {
        if (res.data.errors) throw Error(res.data.errors[0].message);
        return res.data.data.ads_filters_modelbybrand;
    }).catch(err => { throw Error(err.message); });
}